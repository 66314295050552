import { type ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto } from '@flyward/assets/models'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { contractualReserveReadOnlyColumns } from './constants'
import { GenericTable } from '@flyward/platform/components/Table/GenericTable'
import {
  BooleanCellWithLabel,
  NumberCellWithLabel,
  NumberDisplayType,
  TextCellWithLabel,
  type UtilizationUnits,
  UtilizationUnitsDisplay,
} from '@flyward/platform'

interface IContractualReserveReadOnlyTabProps {
  isMrFlag: boolean
  isMrFlagFirstRun: boolean
  prMrRate: number | null | undefined
  prMrRateType: UtilizationUnits | null | undefined
  prMrAgreedEscalation: number
  currentPrMrFund: number | null | undefined
  performanceRestorationReserves: ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto[]
  isEolActive: boolean
}

const ContractualReserveReadOnlyTab = ({
  isMrFlag,
  isMrFlagFirstRun,
  prMrRate,
  prMrRateType,
  prMrAgreedEscalation,
  currentPrMrFund,
  performanceRestorationReserves,
  isEolActive,
}: IContractualReserveReadOnlyTabProps) => {
  const contractualReserveTable = useReactTable<ContractualFlightHoursToFlightCyclesPerformanceRestorationReserveDto>({
    data: performanceRestorationReserves,
    columns: contractualReserveReadOnlyColumns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  return (
    <>
      <div className="flex flex-col gap-y-2">
        <div className="flex gap-x-6">
          <BooleanCellWithLabel className="basis-1/6" label="MR Flag First Run" info={isMrFlagFirstRun} preventInfo={!isMrFlag && !isEolActive} />
          <NumberCellWithLabel
            className="basis-1/6"
            label="PR MR Agreed Escalation"
            info={prMrAgreedEscalation}
            preventInfo={!isMrFlag && !isEolActive}
            displayType={NumberDisplayType.Percentage}
          />
          <NumberCellWithLabel
            className="basis-1/6"
            label="PR MR rate"
            info={prMrRate}
            preventInfo={!isMrFlag && !isEolActive}
            currencyDecimals={2}
            displayType={NumberDisplayType.Currency}
          />
          <TextCellWithLabel className="basis-1/6" label="PR MR rate type" info={UtilizationUnitsDisplay(prMrRateType)} preventInfo={!isMrFlag} />
          <NumberCellWithLabel
            className="basis-1/6"
            label="Current PR MR fund"
            info={currentPrMrFund}
            preventInfo={!isMrFlag && !isEolActive}
            displayType={NumberDisplayType.CurrencyRounded}
          />
          <div className="basis-1/6" />
        </div>
      </div>
      <GenericTable {...contractualReserveTable} />
    </>
  )
}

export { ContractualReserveReadOnlyTab }
export type { IContractualReserveReadOnlyTabProps }
