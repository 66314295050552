export enum CheckType {
  Epr = 1,
  EngineLlpReplacement = 2,
  LandingGearOverhaul = 4,
  ApuPr = 5,
  ApuLlpReplacement = 6,

  // number of months
  Airframe3y = 36,
  Airframe6y = 72,
  Airframe8y = 96,
  Airframe9y = 108,
  Airframe12y = 144,
  Airframe18y = 216,
  Airframe24y = 288,
}

export const CheckTypeDisplay = (value: CheckType) => {
  switch (value) {
    case CheckType.Epr:
      return 'PR'
    case CheckType.EngineLlpReplacement:
      return 'LLP'
    case CheckType.LandingGearOverhaul:
      return 'Overhaul'
    case CheckType.ApuPr:
      return 'PR'
    case CheckType.ApuLlpReplacement:
      return 'LLP'
    case CheckType.Airframe3y:
      return '3y'
    case CheckType.Airframe6y:
      return '6Y'
    case CheckType.Airframe8y:
      return '8Y'
    case CheckType.Airframe9y:
      return '9y'
    case CheckType.Airframe12y:
      return '12Y'
    case CheckType.Airframe18y:
      return '18y'
    case CheckType.Airframe24y:
      return '24y'
  }
}

export const CheckTypeTableDisplay = (value: CheckType) => {
  switch (value) {
    case CheckType.Epr:
      return 'PR'
    case CheckType.EngineLlpReplacement:
      return 'LLP'
    case CheckType.LandingGearOverhaul:
      return 'LG'
    case CheckType.ApuPr:
      return 'APU'
    case CheckType.ApuLlpReplacement:
      return 'LLP'
    case CheckType.Airframe3y:
      return '3y'
    case CheckType.Airframe6y:
      return '6y'
    case CheckType.Airframe8y:
      return '8y'
    case CheckType.Airframe9y:
      return '9y'
    case CheckType.Airframe12y:
      return '12y'
    case CheckType.Airframe18y:
      return '18y'
    case CheckType.Airframe24y:
      return '24y'
  }
}

export enum ComponentTypesAPIURIs {
  Airframe = 'airframes',
  EngineUnit = 'engine-units',
  AuxiliaryPowerUnit = 'auxiliary-power-units',
  EnginePerformanceRestoration = 'engine-performance-restorations',
  EngineLifeLimitedPart = 'engine-llps',
  LandingGear = 'landing-gears',
  ThrustReversers = 'thrust-reversers',
}

export const getEngineChecks = (): CheckType[] => {
  return [CheckType.Epr, CheckType.EngineLlpReplacement]
}

export const isEngineCheck = (checkType: CheckType): boolean => {
  return getEngineChecks().includes(checkType)
}
