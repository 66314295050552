export enum AirframeCheckType {
  Airframe3y = 36,
  Airframe6y = 72,
  Airframe8y = 96,
  Airframe9y = 108,
  Airframe12y = 144,
  Airframe18y = 216,
  Airframe24y = 288,
}

export const AirframeCheckTypeDisplay = (value: AirframeCheckType) => {
  switch (value) {
    case AirframeCheckType.Airframe3y:
      return '3y'
    case AirframeCheckType.Airframe6y:
      return '6Y'
    case AirframeCheckType.Airframe8y:
      return '8Y'
    case AirframeCheckType.Airframe9y:
      return '9y'
    case AirframeCheckType.Airframe12y:
      return '12Y'
    case AirframeCheckType.Airframe18y:
      return '18y'
    case AirframeCheckType.Airframe24y:
      return '24y'
  }
}
