import { format, isValid as isValidDateFns, parse } from 'date-fns'
import * as React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Button, ButtonVariant } from '../_shadcn/button'
import { Input as CnInput, InputVariant } from '../_shadcn/input'
import { cn } from '../utils'
import { isoDateFormat } from '../../helpers/dateHelpers'
import { Icon, IconVariant } from '../Icon'
import { Label } from '../Label'
import { Size } from '../common'
import { isEmpty, isNil } from 'lodash'

enum InputType {
  Currency = 'currency',
  Email = 'email',
  NaturalNumber = 'naturalNumber',
  Password = 'password',
  Percentage = 'percentage',
  Search = 'search',
  Text = 'text',
  Alphanumeric = 'alphanumeric',
  DateOnly = 'dateOnly',
  Decimal = 'decimal',
}

interface IInputProps {
  inputClassName?: string
  labelClassName?: string
  disabled?: boolean
  hasXButton?: boolean
  label?: string
  id?: string
  placeholder?: string
  setValueAfterValidation?: (value: string) => void
  setRawValueOnChange?: (value: string) => void
  setRawValueOnBlur?: (value: string) => void
  setIsValid?: (isValid: boolean) => void
  type?: InputType
  controlledValue?: string
  hasAutoFocus?: boolean
  showErrorMessage?: boolean
  preventNull?: boolean
}

const Input = ({
  inputClassName,
  labelClassName,
  disabled,
  hasXButton,
  label,
  placeholder,
  controlledValue,
  setValueAfterValidation = (_value) => {},
  setRawValueOnChange = (_value) => {},
  setRawValueOnBlur = (_value) => {},
  setIsValid = (_isValid) => {},
  type = InputType.Text,
  id = uuidv4(),
  hasAutoFocus = false,
  showErrorMessage = true,
  preventNull = false,
  ...props
}: Readonly<IInputProps>) => {
  const [isValid, setValidity] = React.useState(true)
  const [internalValue, setInternalValue] = React.useState<string>(controlledValue ?? '')
  const valueDisplay = internalValue?.toString()

  let variant: InputVariant
  let allowOnlyNaturalNumbers = false
  let validateAlphanumerics = false
  let allowOnlyDate = false
  let validateEmail = false
  let preSymbol = null
  let preSymbolClassName = ''
  let postSymbol = null
  let postSymbolClassName = ''
  const dynamicPaddingLeft = `${(valueDisplay?.length ?? 0) * 9 + 16}px`

  switch (type) {
    case InputType.Currency:
      variant = InputVariant.Number
      preSymbolClassName = 'pl-0.5'
      preSymbol = (
        <div className={`absolute inset-y-0 flex items-center`}>
          <span className="pl-0.5">$</span>
        </div>
      )
      break
    case InputType.Email:
      variant = InputVariant.Email
      validateEmail = true
      break
    case InputType.NaturalNumber:
      variant = InputVariant.Number
      allowOnlyNaturalNumbers = true
      break
    case InputType.Decimal:
      variant = InputVariant.Number
      break
    case InputType.Password:
      variant = InputVariant.Password
      break
    case InputType.Percentage:
      variant = InputVariant.Number
      postSymbolClassName = 'pr-0.5'
      postSymbol = (
        <div className="absolute inset-y-0 flex items-center" style={{ paddingLeft: dynamicPaddingLeft, pointerEvents: 'none' }}>
          <span>%</span>
        </div>
      )
      break
    case InputType.Alphanumeric:
      variant = InputVariant.Text
      validateAlphanumerics = true
      break
    case InputType.Search:
      variant = InputVariant.Search
      preSymbolClassName = 'pl-11'
      preSymbol = (
        <div className={`absolute inset-y-0 flex items-center`}>
          <Icon variant={IconVariant.Search} className="ml-3 h-5 w-5 text-primary" />
        </div>
      )
      break
    case InputType.DateOnly:
      variant = InputVariant.Text
      allowOnlyDate = true
      break
    default:
      variant = InputVariant.Text
      break
  }

  const handleNaturalNumberChange = (inputValue: string): string => {
    const numericPattern = /^\d*$/
    const isValidAlphanumeric = numericPattern.test(inputValue)
    setValidity(isValidAlphanumeric || inputValue === '')
    setIsValid(isValidAlphanumeric || inputValue === '')
    return isValidAlphanumeric || inputValue === '' ? Math.max(Number(inputValue), 0).toFixed(0) : ''
  }

  const handleDateChange = (inputValue: string): string => {
    const parsedDate = parse(inputValue, isoDateFormat, new Date())

    const isValidDate = isValidDateFns(parsedDate) && inputValue === format(parsedDate, isoDateFormat)
    setValidity(isValidDate || inputValue === '')
    setIsValid(isValidDate || inputValue === '')
    return isValidDate || inputValue === '' ? inputValue : ''
  }

  const handleAlphanumericChange = (inputValue: string): string => {
    const alphanumericPattern = /^[.a-zA-Z0-9_-]+(?: [.a-zA-Z0-9_-]+)*$/
    const isValidAlphanumeric = alphanumericPattern.test(inputValue)
    setValidity(isValidAlphanumeric || inputValue === '')
    setIsValid(isValidAlphanumeric || inputValue === '')
    return isValidAlphanumeric || inputValue === '' ? inputValue : ''
  }

  const handleEmailChange = (inputEmail: string): string => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const isEmailValid = emailRegex.test(inputEmail)
    setValidity(isEmailValid || inputEmail === '')
    setIsValid(isEmailValid || inputEmail === '')
    return isEmailValid || inputEmail === '' ? inputEmail : ''
  }

  const updateValue = (value: string) => {
    setInternalValue(value)
    setRawValueOnChange(value)

    if (allowOnlyNaturalNumbers) {
      value = handleNaturalNumberChange(value)
    }

    if (validateAlphanumerics) {
      value = handleAlphanumericChange(value)
    }

    if (allowOnlyDate) {
      value = handleDateChange(value)
    }

    if (validateEmail) {
      value = handleEmailChange(value)
    }

    if (isEmpty(value) && preventNull) {
      value = '0'
    }

    if (!isEmpty(value)) {
      setValueAfterValidation(value)
    }
  }

  React.useEffect(() => {
    if (!isNil(controlledValue)) {
      setInternalValue(controlledValue)
      updateValue(controlledValue)
    } // updateValue dependency is ignored because I need it only one time
  }, [controlledValue])

  return (
    <div className={cn('relative flex flex-col justify-center gap-y-0.4', inputClassName)}>
      {label != null && (
        <Label htmlFor={id}>
          <p className={cn('!text-xs font-semibold', disabled ?? false ? 'text-text-3' : 'text-text-1', labelClassName)}>{label}</p>
        </Label>
      )}
      <div className="relative">
        <div className="flex flex-row items-center">
          {preSymbol}
          <CnInput
            className={cn('h-9 w-80 justify-between rounded', inputClassName, preSymbolClassName, postSymbolClassName, !isValid && 'border-error')}
            disabled={disabled}
            data-rawvalue={internalValue}
            id={id}
            variant={variant}
            value={valueDisplay}
            onChange={(event) => {
              updateValue(event.target.value)
            }}
            onBlur={(event) => {
              setRawValueOnBlur(event.target.value)
            }}
            placeholder={placeholder}
            autoFocus={hasAutoFocus}
            {...props}
          />
          {postSymbol}
          {(hasXButton ?? false) && internalValue != null && (
            <Button
              className="z-50"
              data-testid="input-x-button"
              onClick={() => {
                setValueAfterValidation('')
              }}
              variant={ButtonVariant.Ghost}
            >
              <Icon className="cursor-pointer" size={Size.Small} variant={IconVariant.Clear} />
            </Button>
          )}
        </div>
        {showErrorMessage && (
          <div className="absolute top-8 z-50 flex">
            {!isValid && type === InputType.DateOnly && (
              <p className="mt-1 text-xs text-red-500">Invalid date format. Use {isoDateFormat}. Digits only.</p>
            )}
            {!isValid && type === InputType.Alphanumeric && (
              <p className="mt-1 text-xs text-red-500">Invalid input. Only alphanumeric characters are allowed.</p>
            )}
            {!isValid && type === InputType.Email && (
              <p className="mt-1 text-xs text-red-500">Invalid input. Only alphanumeric characters are allowed.</p>
            )}
            {!isValid && type === InputType.NaturalNumber && (
              <p className="mt-1 text-xs text-red-500">Invalid input. Only natural numbers are allowed.</p>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export { Input, InputType, type IInputProps }
